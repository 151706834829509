.tableContainer {
  display: flex;
  justify-content: space-around;
  font-size: 1rem;
}

.tableContainer ul {
  margin: 0 60px;
  list-style-type: none;
}

.tableContainer li {
  margin: 20px;
}

.tableContainer li label {
  font-weight: 700;
}
